import React, { useState } from 'react';
import { useEffect, useRef } from 'react';
// import logo from './assets/jakes-logo.svg';
import logo from './assets/jakes-logo.svg'
import './App.css';

// import sortedData from './assets/sorted-items.json';
// import drinks from './assets/drinks.json';
// import cocktails from './assets/cocktails.json';
// import mocktails from './assets/mocktails.json';
// import shooters from './assets/shooters.json';

// import { Modal } from 'flowbite';

function App() {

	const [sortedData, setSortedData] = useState();
	const [drinks, setDrinks] = useState();
	const [cocktails, setCocktails] = useState([]);
	const [mocktails, setMocktails] = useState([]);
	const [shooters, setShooters] = useState([]);

	// console.log( 'shooters' , Window['shooters'] );
	// console.log( 'shooters' , shooters );

	const thisHourMillis = new Date().getTime();

	const base_url = "https://jakesbarandkitchen.com";

	const getSortedData = () => {
		fetch(`${base_url}/static/sorted-items.json?time=`+thisHourMillis)
		.then(response => response.json())
		.then(data => {
			console.log( data)
			setSortedData(data)
		})
		.catch(error => {	
			console.log( error )
		});
	};

	const getDrinks = () => {
		fetch(`${base_url}/static/drinks.json?time=`+thisHourMillis)
		.then(response => response.json())
		.then(data => {
			setDrinks(data)
		})
		.catch(error => {	
			console.log( error )
		});
	};

	const getCocktails = () => {
		fetch(`${base_url}/static/cocktails.json?time=`+thisHourMillis)
		.then(response => response.json())
		.then(data => {
			setCocktails(data)
		})
		.catch(error => {	
			console.log( error )
		});
	};

	const getMocktails = () => {
		fetch(`${base_url}/static/mocktails.json?time=`+thisHourMillis)
		.then(response => response.json())
		.then(data => {
			setMocktails(data)
		})
		.catch(error => {	
			console.log( error )
		});
	};

	const getShooters = () => {
		fetch(`${base_url}/static/shooters.json?time=`+thisHourMillis)
		.then(response => response.json())
		.then(data => {
			setShooters(data)
		})
		.catch(error => {	
			console.log( error )
		});
	};

	useEffect(()=>{
		getSortedData();
		getDrinks();
		getCocktails();
		getMocktails();
		getShooters();
	},[]);

	if (  sortedData != undefined ) {
		for (const key in sortedData) {
			if (Object.hasOwnProperty.call(sortedData, key)) {
				const element = sortedData[key];
				for (let i = 0; i < element.length; i++) {
					const item = element[i];
					item['item'] = item['item'].toLowerCase();
					// console.log( item['item'] )
				}
			}
		}
	}

	let breakfast = [];
	try {
		breakfast = sortedData['BREAKFAST'];
	} catch (error) {
		breakfast = [];
	}
	
	let breakfast_add_ons = [];
	try {
		breakfast_add_ons = sortedData['BREAKFAST ADD ONS'];
	} catch (error) {
		breakfast_add_ons = [];
	}

	let quick_meals = [];
	try {
		quick_meals = sortedData['QUICK MEALS'];
	} catch (error) {
		quick_meals = [];
	}


	let burgers = [];
	try {
		burgers = sortedData['BURGER'];
	} catch (error) {
		burgers = [];
	}

	let burgers_add_ons = [];
	try {
		burgers_add_ons = sortedData['BURGER ADD ONS'];
	} catch (error) {
		burgers_add_ons = [];
	}

	let main_meal = [];
	try {
		main_meal = sortedData['MAIN MEALS'];
	} catch (error) {
		main_meal = [];
	}
	
	let off_the_grill = [];
	try {
		off_the_grill = sortedData['OFF THE GRILL'];
	} catch (error) {
		off_the_grill = [];
	}

	let sides = [];
	try {
		sides = sortedData['SIDE'];
	} catch (error) {
		sides = [];
	}

	let taco_tuesdays = [];
	try {
		taco_tuesdays = sortedData['TACCO TUESDAY'];
	} catch (error) {
		taco_tuesdays = [];
	}
	
	let wings_only = [];
	try {
		wings_only = sortedData['WINGS ONLY'];
	} catch (error) {
		wings_only = [];
	}
	let wings_promo = [];
	try {
		wings_promo = sortedData['WINGS PROMO(ALL DAY)'];
	} catch (error) {
		wings_promo = [];
	}
	
	let wings_fries = [];
	try {
		wings_fries = sortedData['WINGS AND FRIES'];
	} catch (error) {
		wings_fries = [];
	}

	let wings_fries_soda = [];
	try {
		wings_fries_soda = sortedData['WINGS,FRIES AND SODA'];
	} catch (error) {
		wings_fries_soda = [];
	}

	let drinks_list = drinks;

	try {
		sides.sort((a,b) => a.price - b.price)
	} catch (error) {
		console.log( error )
	}

	const [showModal, setShowModal] = React.useState(true);

	const wrapperRef = useRef(null);
  	useOutsideAlerter(wrapperRef);

	// setTimeout( () => {
	// 	if ( showModal == false ) setShowModal(true)
	// },100)

	// const options = {
	// 	placement: 'bottom-right',
	// 	backdrop: 'dynamic',
	// 	backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
	// 	closable: true,
	// 	onHide: () => {
	// 		console.log('modal is hidden');
	// 	},
	// 	onShow: () => {
	// 		console.log('modal is shown');
	// 	},
	// 	onToggle: () => {
	// 		console.log('modal has been toggled');
	// 	}
	//   };

	// const modal = new Modal( document.getElementById("popup-modal") , options);
	// modal.show();

	return (
		<div className='p-4 bg-black text-white flex flex-col items-center' >
			<div className='w-full my-4 flex flex-row justify-center' ><img src={logo} width='200' /></div>
			<h2 className='text-center' >
			</h2>

			<div className='p-4 rounded-lg w-full md:w-auto bg-white font-bold my-4 capitalize text-center text-red-900 text-2xl' >
				We Do Deliveries <br className='block md:hidden' /> <b>Call: 0710 450 211</b>
			</div>

			<h3 className='uppercase text-2xl font-black text-center p-4 text-red-900' >Breakfast</h3>
			<div className='flex flex-row flex-wrap justify-between w-full md:w-3/4' >
				{( () => {
					let breakfast_items = [];
					for (let i = 0; i < breakfast.length; i++) {
						const element = breakfast[i];
						breakfast_items.push(
							<div className='flex flex-col w-full md:w-2/5 mt-2' key={i} >
								<div className='w-full flex flex-row justify-between' >
									<p className='capitalize' >{element.item}</p>
									<p className=' font-bold' >KES {element.price}</p>
								</div>
							</div>
						)
					}
					return breakfast_items;
				})()}
			</div>

			<h3 className='mt-4 uppercase text-2xl font-black text-center p-4 text-red-900' >QUICK MEALS</h3>
			<div className='flex flex-row flex-wrap justify-between w-full md:w-3/4' >
				{( () => {
					let quick_meals_items = []
					for (let i = 0; i < quick_meals.length; i++) {
						const element = quick_meals[i];
						quick_meals_items.push(
							<div className='flex flex-col w-full md:w-2/5 mt-2' key={i} >
								<div className='w-full flex flex-row justify-between' >
									<p className='capitalize' >{element.item}</p>
									<p className=' font-bold' >KES {element.price}</p>
								</div>
							</div>
						)
					}
					return quick_meals_items;
				})()}
			</div>

			<div className='flex flex-col bg-white text-red-900 my-4 p-2 rounded-lg items-center w-full' >
				<div className='flex flex-row flex-wrap justify-between w-full md:w-3/4' >
							
					<div className='flex flex-col w-full mt-2' >
						<p className='text-center uppercase font-bold' >Taco Tuesday's</p>
						<p className='text-center uppercase font-semibold italic' >Get 4 Tacos @ KES 1,000 /-</p>
					</div>
							
				</div>
			</div>

			<h3 className='mt-4 uppercase text-2xl font-black text-center p-4 text-red-900' >BURGER</h3>
			<div className='flex flex-row flex-wrap justify-between w-full md:w-3/4' >
				{( () => {
					let burgers_items = []
					for (let i = 0; i < burgers.length; i++) {
						const element = burgers[i];
						burgers_items.push(
							<div className='flex flex-col w-full md:w-2/5 mt-2' key={i} >
								<div className='w-full flex flex-row justify-between' >
									<p className='capitalize' >{element.item}</p>
									<p className=' font-bold' >KES {element.price}</p>
								</div>
							</div>
						)
					}
					return burgers_items;
				})()}
			</div>

			{/* <h3 className='mt-4 uppercase text-2xl font-black text-center p-4 text-red-900' >BURGER ADD ONS</h3> */}
			<div className='flex flex-col flex-wrap justify-between w-full md:w-3/4' >
				{( () => {
					let burgers_add_ons_items = []
					for (let i = 0; i < burgers_add_ons.length; i++) {
						const element = burgers_add_ons[i];
						burgers_add_ons_items.push(
							<div className='flex flex-col w-full md:w-2/5 mt-2 italic ' key={i} >
								<div className='w-full flex flex-row justify-between' >
									<p className='capitalize ml-4' >*{element.item}</p>
									<p className=' font-bold' >KES {element.price}</p>
								</div>
							</div>
						)
					}
					return burgers_add_ons_items;
				})()}
			</div>

			<h3 className='mt-4 uppercase text-2xl font-black text-center p-4 text-red-900' >MAIN MEALS</h3>
			<div className='flex flex-row flex-wrap justify-between w-full md:w-3/4' >
				{( () => {
					let main_meal_items = []
					for (let i = 0; i < main_meal.length; i++) {
						const element = main_meal[i];
						main_meal_items.push(
							<div className='flex flex-col w-full md:w-2/5 mt-2' key={i} >
								<div className='w-full flex flex-row justify-between' >
									<p className='capitalize' >{element.item}</p>
									<p className=' font-bold' >KES {element.price}</p>
								</div>
							</div>
						)
					}
					return main_meal_items;
				})()}
			</div>

			

			<h3 className='mt-4 uppercase text-2xl font-black text-center p-4 text-red-900' >Sides</h3>
			<div className='flex flex-row flex-wrap justify-between w-full md:w-3/4' >
				{( () => {
					let sides_items = []
					for (let i = 0; i < sides.length; i++) {
						const element = sides[i];
						sides_items.push(
							<div className='flex flex-col w-full md:w-2/5 mt-2' key={i} >
								<div className='w-full flex flex-row justify-between' >
									<p className='capitalize' >{element.item}</p>
									<p className=' font-bold' >KES {element.price}</p>
								</div>
							</div>
						)
					}
					return sides_items;
				})()}
			</div>

			{/* <div className='flex flex-col bg-white text-red-900 mt-4 p-4 rounded-lg' >
				<h3 className=' uppercase text-2xl font-black text-center ' >TACCO TUESDAY</h3>
				<div className='flex flex-row flex-wrap justify-between w-full' >
					{( () => {
						let taco_tuesdays_items = []
						for (let i = 0; i < taco_tuesdays.length; i++) {
							const element = taco_tuesdays[i];
							taco_tuesdays_items.push(
								<div className='flex flex-col w-full mt-2' key={i} >
									<div className='w-full flex flex-row justify-between' >
										<p className='capitalize' >{element.item}</p>
										<p className=' font-bold' >KES {element.price}</p>
									</div>
								</div>
							)
						}
						return taco_tuesdays_items;
					})()}
				</div>
			</div> */}

			<h3 className='mt-4 uppercase text-2xl font-black text-center p-4 text-red-900' >WINGS BY CHRIS P.WINGS</h3>
			<div className='flex flex-col md:flex-row flex-wrap md:space-x-4 my-4 w-full md:w-3/4' >
				<span>▶ Honey Mustard</span>
				<span>▶ Lemon pepper</span>
				<span>▶ Buffalo ( HOT )</span>
				<span>▶ Garlic Parmesan</span>
				<span>▶ BBQ</span>
				<span>▶ Who’s Yo Daddy</span>
				<span>▶ Teriyaki Sesame</span>
				<span>▶ Coconut Curry ( MILD )</span>
			</div>
			<div className='flex flex-col items-center w-full mt-4' >
				<div className='flex flex-row flex-wrap justify-between w-full md:w-3/4 font-bold mb-4' >
					<span className='w-1/4 text-center' >Wing <br className='md:hidden'/>Count</span>
					<span className='w-1/4 text-right' >Wings <br className='md:hidden' />Only</span>
					<span className='w-1/4 text-right' >Wings <br className='md:hidden' />+ Fries</span>
					<span className='w-1/4 text-right' >Wings <br className='md:hidden' />+ Fries <br/>+ Soda</span>
				</div>
				{( () =>  {
					let wings_combo = [];
					for (let i = 0; i < wings_fries.length; i++) {
						const element = wings_fries[i];
						wings_combo.push(
							<div className='flex flex-row flex-wrap justify-between w-full md:w-3/4' key={i} >
								<p className='w-1/4 text-center' > { element.item.replace(' wings','') } </p>
								<p className='w-1/4 text-right' > { wings_only[i].price } </p>
								<p className='w-1/4 text-right' > { parseInt(element.price).toLocaleString() } </p>
								<p className='w-1/4 text-right' > { parseInt(wings_fries_soda[i].price).toLocaleString() } </p>
							</div>
						)
					}
					return wings_combo;
				})()}
			</div>

			<div className='flex flex-col items-center w-full ' >
				<div className='flex flex-row flex-wrap justify-between w-full md:w-3/4 font-bold mb-4' >
					{( () => {
						let taco_tuesdays_items = []
						for (let i = 3; i < wings_only.length; i++) {
							const element = wings_only[i];
							taco_tuesdays_items.push(
								<div className='flex flex-col w-full mt-2' key={i} >
									<div className='w-full flex flex-row items-center justify-between' >
										<p className='capitalize w-1/4 text-center' >{ element.item.replace(' wings','') }</p>
										<p className='w-1/4 text-right' >{ parseInt(element.price).toLocaleString() }</p>
										<p className='w-1/4 text-right' ></p>
										<p className='w-1/4 text-right' ></p>
									</div>
								</div>
							)
						}
						return taco_tuesdays_items;
					})()}
				</div>
			</div>

			<p className='text-center font-medium italic my-4' >
				*Pricing applicable to all wing flavours.<br/>
				*You can enjoy different flavours in multiples of 5.<br/>
				*Get bigger discounts on all bulk orders starting from 15 wings<br/>
			</p>

			<div className='flex flex-col bg-white text-red-900 my-4 p-2 rounded-lg items-center' >
				<div className='flex flex-row flex-wrap justify-between w-full md:w-3/4' >
					{( () => {
						let wings_promo_items = []
						for (let i = 0; i < wings_promo.length; i++) {
							const element = wings_promo[i];
							wings_promo_items.push(
								<div className='flex flex-col w-full mt-2' key={i} >
									<div className='w-full flex flex-row items-center justify-between' >
										<p className='text-center uppercase font-bold' >
											{element.item.split('(')[0] + ' ' } <br/>
											{element.item.split('(')[1] + ' ' }
											KES {element.price}
										</p>
									</div>
								</div>
							)
						}
						return wings_promo_items;
					})()}
				</div>
			</div>
			
			<h3 className=' uppercase text-2xl font-black text-center ' >Drinks</h3>
			<div className='flex flex-col justify-between w-full md:w-3/4' >
				{( () => {
					let drink_items = []
					let drinks_index = 0;
					for (const key in drinks_list) {
						if (Object.hasOwnProperty.call(drinks_list, key)) {
							const element = drinks_list[key];
							
							for (let i = 0; i < element.length; i++) {
								const x = element[i];

								if ( key === 'WINES' ) {
									if ( i === 0 ) {
										drinks_index += 1;
										drink_items.push(
											<div className='flex flex-row w-full mt-4' key={drinks_index} >
												<p className='uppercase font-black text-2xl w-3/5' >{key}</p>
												<p className='uppercase w-1/5 text-right' >Bottle</p>
												<p className='uppercase w-1/5 text-right' >Glass</p>
											</div>
										)
									}
									drinks_index += 1;
									drink_items.push(
										<div className='flex flex-row w-full' key={drinks_index} >
											<p className='uppercase w-3/5' >{x.name	}</p>
											<p className='uppercase w-1/5 text-right' >
												{ parseInt(x.price).toLocaleString() }
											</p>
											<p className='uppercase w-1/5 text-right' >
												{
													x.glass || '-' 
												}
											</p>
										</div>
									)
								} else if ( key === 'JUICE' ) {
									if ( i === 0 ) {
										drinks_index += 1;
										drink_items.push(
											<div className='flex flex-row w-full mt-4' key={drinks_index} >
												<p className='uppercase font-black text-2xl w-4/5' >{key}</p>
												<p className='uppercase w-1/5 text-right' >Box</p>
											</div>
										)
									}
									drinks_index += 1;
									drink_items.push(
										<div className='flex flex-row w-full' key={drinks_index} >
											<p className='uppercase w-4/5' >{x.name	}</p>
											<p className='uppercase w-1/5 text-right' >
												{ parseInt(x.price).toLocaleString() }
											</p>
										</div>
									)
								} else if ( key === 'CANS' ) {
									if ( i === 0 ) {
										drinks_index += 1;
										drink_items.push(
											<div className='flex flex-row w-full mt-4' key={drinks_index} >
												<p className='uppercase font-black text-2xl w-4/5' >{key}</p>
												<p className='uppercase w-1/5 text-right' >Cans</p>
											</div>
										)
									}
									drinks_index += 1;
									drink_items.push(
										<div className='flex flex-row w-full' key={drinks_index} >
											<p className='uppercase w-4/5' >{x.name	}</p>
											<p className='uppercase w-1/5 text-right' >
												{ parseInt(x.price).toLocaleString() }
											</p>
										</div>
									)
								}  else if ( key === 'BEERS' || key === 'SOFT DRINKS' ) {

									if ( i === 0 ) {
										drinks_index += 1;
										drink_items.push(
											<div className='flex flex-row w-full mt-4' key={drinks_index} >
												<p className='uppercase font-black text-2xl w-4/5' >{key}</p>
												<p className='uppercase w-1/5 text-right' >Bottle</p>
											</div>
										)
									}
									drinks_index += 1;
									drink_items.push(
										<div className='flex flex-row w-full' key={drinks_index} >
											<p className='uppercase w-4/5' >{x.name	}</p>
											<p className='uppercase w-1/5 text-right' >
												{ parseInt(x.price).toLocaleString() }
											</p>
										</div>
									)
								} else if( key === 'CIGARETTES' || key === 'MEDICINE' ) {

									// if ( i === 0 ) {
									// 	drinks_index += 1;
									// 	drink_items.push(
									// 		<div className='flex flex-row w-full mt-4' key={drinks_index} >
									// 			<p className='uppercase font-black text-2xl w-3/5' >{key}</p>
									// 			<p className='uppercase w-1/5 text-right' >price</p>
									// 			<p className='uppercase w-1/5 text-right' ></p>
									// 		</div>
									// 	)
									// }
									// drinks_index += 1;
									// drink_items.push(
									// 	<div className='flex flex-row w-full' key={drinks_index} >
									// 		<p className='uppercase w-3/5' >{x.name	}</p>
									// 		<p className='uppercase w-1/5 text-right' >
									// 			{ parseInt(x.price).toLocaleString() }
									// 		</p>
									// 		<p className='uppercase w-1/5 text-right' ></p>
									// 	</div>
									// )
								} else {
									if ( i === 0 ) {
										drinks_index += 1;
										drink_items.push(
											<div className='flex flex-row w-full mt-4' key={drinks_index} >
												<p className='uppercase font-black text-2xl w-3/5' >{key}</p>
												<p className='uppercase w-1/5 text-right' >Bottle</p>
												<p className='uppercase w-1/5 text-right' >Tot</p>
											</div>
										)
									}
									drinks_index += 1;
									drink_items.push(
										<div className='flex flex-row w-full' key={drinks_index} >
											<p className='uppercase w-3/5' >{x.name	}</p>
											<p className='uppercase w-1/5 text-right' >
												{ parseInt(x.price).toLocaleString() }
											</p>
											<p className='uppercase w-1/5 text-right' >
												{
													x.tot || '-' 
												}
											</p>
										</div>
									)
								}
							}
							
						}
					}
					return drink_items;
				})()}
			</div>
			
			<hr className='h-x w-full border-dashed border-red-900 mt-4' />

			<div className='flex flex-col md:flex-row my-8 ' >
				<div className='flex flex-col w-full md:w-1/2 md:items-center md:justify-center mt-4' >
					<h3 className='bon-font text-center cocktail-header' >Cocktails</h3>
					<img alt='Jakes Bar & Kitchen' src={require('./assets/imgs/margarita.png')} className='md:w-3/4 ' />
				</div>
				<div className='flex flex-col w-full md:w-1/2 md:flex-row md:flex-wrap' >
					{( () => {
						let cocktails_list = [];
						for (let i = 0; i < cocktails.length; i++) {
							const element = cocktails[i];
							// if ( i+1 === shooters.length ) {
							// 	cocktails_list.push(
							// 		<div key={i} className="flex flex-col w-full md:w-2/5 md:mx-2 my-4 text-center" >
							// 			<p className='bon-font capitalize text-4xl' >{ element.name.split(' ').join('   ') }</p>
							// 			<p className='bon-font capitalize tracking-widest text-xl mt-2' >  { element.text }</p>
							// 			<p>KES { parseInt(element.price).toLocaleString() }</p>
							// 		</div>
							// 	)
							// } else {
								cocktails_list.push(
									<div key={i} className="flex flex-col w-full md:w-2/5 md:mx-2 my-4 text-center" >
										<p className='bon-font capitalize text-4xl' >{ element.name }</p>
										<p className='bon-font capitalize tracking-widest text-xl mt-2' >  { element.text }</p>
										<p>KES { parseInt(element.price).toLocaleString() }</p>
										<hr className='w-full border border-red-900 mt-4' />
									</div>
								)
							// }
						}
						return cocktails_list;
					})()}
				</div>
			</div>
			
			<hr className='h-x w-full border-dashed border-red-900' />

			<div className='flex flex-col justify-center items-center w-full md:flex-row my-8 ' >
				<div className='flex flex-col w-full md:w-3/4 md:items-center md:justify-center mt-4' >
					<h3 className='bon-font text-center cocktail-header' >Mocktails</h3>
					<img alt='Jakes Bar & Kitchen' src={require('./assets/imgs/mojito.png')} className='md:w-2/4 mb-4 md:mb-0' />
				</div>
				<div className='flex flex-col w-full md:flex-wrap' >
					{( () => {
						let mocktails_list = [];
						for (let i = 0; i < mocktails.length; i++) {
							const element = mocktails[i];
							if ( i+1 === shooters.length ) {
								mocktails_list.push(
									<div key={i} className="flex flex-col w-full md:w-3/4 md:mx-2 my-4 text-center" >
										<p className='bon-font capitalize text-4xl mb-4' >{ element.name }</p>
										<p>KES { parseInt(element.price).toLocaleString() }</p>
									</div>
								)
							} else {
								mocktails_list.push(
									<div key={i} className="flex flex-col w-full md:w-3/4 md:mx-2 my-4 text-center" >
										<p className='bon-font capitalize text-4xl mb-4' >{ element.name }</p>
										<p>KES { parseInt(element.price).toLocaleString() }</p>
										<hr className='w-full border border-red-900 mt-4' />
									</div>
								)
							}
						}
						return mocktails_list;
					})()}
				</div>
			</div>
			
			<hr className='h-x w-full border-dashed border-red-900' />

			<div className='flex flex-col justify-center items-center w-full md:flex-row my-8 ' >
				<div className='flex flex-col w-full md:w-3/4 md:items-center md:justify-center mt-4' >
					<h3 className='bon-font text-center cocktail-header' >Shooters</h3>
					<img alt='Jakes Bar & Kitchen' src={require('./assets/imgs/shooters-old.png')} className='md:w-2/4 mb-4 md:mb-0' />
				</div>
				<div className='flex flex-col w-full md:flex-wrap' >
					{( () => {
						let shooters_list = [];
						for (let i = 0; i < shooters.length; i++) {
							const element = shooters[i];
							if ( i+1 === shooters.length ) {
								shooters_list.push(
									<div key={i} className="flex flex-col w-full md:w-3/4 md:mx-2 my-4 text-center" >
										<p className='bon-font capitalize text-4xl mb-4' >{ element.name }</p>
										<p>KES { parseInt(element.price).toLocaleString() }</p>
									</div>
								)
							} else {
								shooters_list.push(
									<div key={i} className="flex flex-col w-full md:w-3/4 md:mx-2 my-4 text-center" >
										<p className='bon-font capitalize text-4xl mb-4' >{ element.name }</p>
										<p>KES { parseInt(element.price).toLocaleString() }</p>
										<hr className='w-full border border-red-900 mt-4' />
									</div>
								)
							}
						}
						return shooters_list;
					})()}
				</div>
			</div>

			
				</div>
			);
}

function useOutsideAlerter(ref) {
	useEffect(() => {
	  /**
	   * Alert if clicked on outside of element
	   */
	  function handleClickOutside(event) {
		if (ref.current && !ref.current.contains(event.target)) {
		  alert("You clicked outside of me!");
		}
	  }
	  // Bind the event listener
	  document.addEventListener("mousedown", handleClickOutside);
	  return () => {
		// Unbind the event listener on clean up
		document.removeEventListener("mousedown", handleClickOutside);
	  };
	}, [ref]);
  }

export default App;
